import { FetchArgs } from '@reduxjs/toolkit/query';
import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';
import { CreateCspAccountInfo } from 'types/cspManagement';
import {
  BaseResponse,
  CspAccountResponse,
  GetCspAccountResponse,
  GetViewerOptionsResponse,
  SearchCSPUserRequestType,
  SearchCSPUserResponseType,
  UploadCspOnboardingDocumentsRequestType,
  UploadCspOnboardingDocumentsResponseType,
} from 'types/service';

import { definitions } from '@/types/schema';
import {
  ChangeAdminStatusRequestType,
  ChangeAdminStatusResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

import { documentUploadBaseApi } from './base/documentUpload';

export const cspManagementApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createCspAccount: builder.mutation<
      CspAccountResponse,
      CreateCspAccountInfo
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `v1/csps`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as CspAccountResponse;
        } else {
          return { data } as unknown as CspAccountResponse;
        }
      },
    }),
    getCspAccount: builder.query<GetCspAccountResponse, { cspId: number }>({
      query: ({ cspId }) => `v1/csps/${cspId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetCspAccountResponse;
        } else {
          return { data } as unknown as GetCspAccountResponse;
        }
      },
    }),
    updateCspAccount: builder.mutation<CspAccountResponse, any>({
      query: ({ id, data }) => {
        const queryObj: any = {
          url: `v1/csps/${id}`,
          body: data,
          method: `PUT`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as CspAccountResponse;
        } else {
          return { data } as unknown as CspAccountResponse;
        }
      },
    }),
    getUserListForCspAssignment: builder.query<
      definitions['ResponseWrapper«List«GetAssignedUsersDto»»'],
      void
    >({
      query: () => `users/csp-assignment-options`,
      transformResponse,
    }),
    getStateByCountry: builder.query<
      BaseResponse<string[]>,
      { country: string }
    >({
      query: ({ country }) => `shared/countries/${country}/state`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BaseResponse<string[]>;
        } else {
          return { data } as unknown as BaseResponse<string[]>;
        }
      },
    }),
    getCitiesByCountryAndState: builder.query<
      BaseResponse<string[]>,
      { payload: { country: string; state: string } }
    >({
      query: ({ payload }) =>
        `shared/countries/${payload.country}/state/${payload.state}/city`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BaseResponse<string[]>;
        } else {
          return { data } as unknown as BaseResponse<string[]>;
        }
      },
    }),
    getZones: builder.query<BaseResponse<string[]>, void>({
      query: () => `shared/zones`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BaseResponse<string[]>;
        } else {
          return { data } as unknown as BaseResponse<string[]>;
        }
      },
    }),
    assignUsersToCsp: builder.mutation<
      BaseResponse<null | true>,
      {
        cspId: number;
        data: { countryGroup: string; userId: number; userType: string };
      }
    >({
      query: ({ cspId, data }) => {
        const queryObj: any = {
          url: `users/${cspId}/assign-users-to-csp`,
          body: data,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse,
    }),
    removeUsersFromCsp: builder.mutation<
      BaseResponse<true | null>,
      { userId: number; cspId: number; data: { countryGroup: string } }
    >({
      query: ({ userId, cspId, data }) => {
        const queryObj: any = {
          url: `users/${userId}/remove-csp/${cspId}`,
          body: data,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse,
    }),
    searchCspUsers: builder.mutation<BaseResponse<any>, any>({
      query: (payload) => ({
        url: `/v1/csps/users/search/${payload.cspId}`,
        method: `POST`,
        body: payload,
      }),
    }),
    searchUsersinCsp: builder.mutation<
      SearchCSPUserResponseType,
      SearchCSPUserRequestType
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `/v1/csps/search`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as SearchCSPUserResponseType;
        } else {
          return { data } as unknown as SearchCSPUserResponseType;
        }
      },
    }),
    addCspUser: builder.mutation({
      query: (payload) => ({
        url: `/v1/csps/${payload.cspId}/users`,
        method: `POST`,
        body: payload,
      }),
    }),
    updateCspUser: builder.mutation({
      query: (payload) => ({
        url: `/v1/csps/users/${payload.userId}`,
        method: `PATCH`,
        body: payload,
      }),
    }),
    deleteCspUser: builder.mutation({
      query: (payload) => ({
        url: `/users/${payload.userId}/deactivate`,
        method: `POST`,
        body: {},
      }),
    }),
    getCspUser: builder.query({
      query: (payload) => ({
        url: `/v1/csps/users/${payload}`,
        method: `GET`,
      }),
      providesTags: [`SingleCspUser`],
    }),
    fetchAllSharedConfig: builder.query({
      query: () => ({
        url: `shared/lists`,
        method: `GET`,
      }),
    }),
    getViewerOptions: builder.query<GetViewerOptionsResponse, void>({
      query: () => ({
        url: `/users/viewer-csp-assignment-options`,
        method: `GET`,
      }),
    }),
    fetchAllCountryData: builder.query({
      query: () => ({
        url: `shared/countries`,
        method: `GET`,
      }),
    }),
    changeAdminStatus: builder.mutation<
      ChangeAdminStatusResponseType,
      ChangeAdminStatusRequestType
    >({
      query: (payload) => ({
        url: `/users/change-admin-status`,
        method: `POST`,
        body: payload,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as ChangeAdminStatusResponseType;
        } else {
          return data as unknown as ChangeAdminStatusResponseType;
        }
      },
      invalidatesTags: [`SingleCspUser`],
    }),
  }),
});

export const cspManagementDocumentUploadApi =
  documentUploadBaseApi.injectEndpoints({
    endpoints: (builder) => ({
      uploadCspOnboardingDocuments: builder.mutation<
        UploadCspOnboardingDocumentsResponseType,
        UploadCspOnboardingDocumentsRequestType
      >({
        query: (payload) => {
          const args: FetchArgs = {
            url: `v1/csps/${payload?.cspId}/documents`,
            method: `POST`,
          };
          const formData = new FormData();

          formData.append(`keyName`, payload?.keyName);
          if (payload?.file) {
            formData.append(`file`, payload?.file as File);
          }
          args[`body`] = formData;
          return args;
        },
      }),
    }),
  });

export const {
  useCreateCspAccountMutation,
  useLazyGetCspAccountQuery,
  useGetCspUserQuery,
  useUpdateCspAccountMutation,
  useGetUserListForCspAssignmentQuery,
  useLazyGetCitiesByCountryAndStateQuery,
  useGetZonesQuery,
  useLazyGetZonesQuery,
  useLazyGetStateByCountryQuery,
  useGetStateByCountryQuery,
  useAssignUsersToCspMutation,
  useRemoveUsersFromCspMutation,
  useAddCspUserMutation,
  useSearchCspUsersMutation,
  useUpdateCspUserMutation,
  useDeleteCspUserMutation,
  useLazyGetCspUserQuery,
  useSearchUsersinCspMutation,
  useFetchAllSharedConfigQuery,
  useGetViewerOptionsQuery,
  useFetchAllCountryDataQuery,
  useChangeAdminStatusMutation,
} = cspManagementApi;

export const { useUploadCspOnboardingDocumentsMutation } =
  cspManagementDocumentUploadApi;
