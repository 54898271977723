import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';

import {
  DeleteStudentInfoByIdRequestType,
  DeleteStudentInfoByIdResponseType,
  DeleteStudentInfoByIdsRequestType,
  DeleteStudentInfoByIdsResponseType,
  GetCmsCountriesResponseType,
  GetCmsLocationsRequestType,
  GetCmsLocationsResponseType,
  GetCmsStatesByCountryResponseType,
  GetCounsellorOptions,
  GetCountryCityStateResponseType,
  GetStudentAcademicDetailsResponseType,
  GetStudentExamDetailsResponseType,
  GetStudentPersonalDetailsResponseType,
  GetStudentProfileInfoRequestType,
  GetStudentWorkDetailsResponseType,
  UpdateStudentAcademicDetailsRequestType,
  UpdateStudentAcademicDetailsResponseType,
  UpdateStudentBackgroundInfoRequestType,
  UpdateStudentBackgroundInfoResponseType,
  UpdateStudentExamDetailsRequestType,
  UpdateStudentExamDetailsResponseType,
  UpdateStudentPersonalDetailsRequestType,
  UpdateStudentPersonalDetailsResponseType,
  UpdateStudentWorkDetailsRequestType,
  UpdateStudentWorkDetailsResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export const studentProfileV1Api = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getStudentPersonalInfo: builder.query<
      GetStudentPersonalDetailsResponseType,
      GetStudentProfileInfoRequestType
    >({
      query: ({ studentId }) => `/students/${studentId}`,
      providesTags: [`GetStudentPersonalInfo`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentPersonalDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as GetStudentPersonalDetailsResponseType;
        }
      },
    }),
    updateStudentPersonalInfo: builder.mutation<
      UpdateStudentPersonalDetailsResponseType,
      UpdateStudentPersonalDetailsRequestType
    >({
      query: ({ body, studentId }) => ({
        url: `/students/${studentId}`,
        method: `PUT`,
        body: body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentPersonalDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as UpdateStudentPersonalDetailsResponseType;
        }
      },
      invalidatesTags: [`GetStudentPersonalInfo`],
    }),
    getStudentAcademicInfo: builder.query<
      GetStudentAcademicDetailsResponseType,
      GetStudentProfileInfoRequestType
    >({
      query: ({ studentId }) => ({
        url: `/students/academic-info/${studentId}`,
        method: `GET`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentAcademicDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as GetStudentAcademicDetailsResponseType;
        }
      },
      providesTags: [`GetStudentAcademicInfo`],
    }),
    updateStudentAcademicInfo: builder.mutation<
      UpdateStudentAcademicDetailsResponseType,
      UpdateStudentAcademicDetailsRequestType
    >({
      query: ({
        studentId,
        highestEducationalQualification,
        academicInfoDetails,
        gapInStudies,
        yearsOfEducation,
      }) => ({
        url: `/students/academic-info/${studentId}`,
        body: {
          highestEducationalQualification,
          academicInfoDetails,
          gapInStudies,
          yearsOfEducation,
        },
        method: `PUT`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentAcademicDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as UpdateStudentAcademicDetailsResponseType;
        }
      },
      invalidatesTags: [`GetStudentAcademicInfo`, `documents`],
    }),
    deleteStudentInfoById: builder.mutation<
      DeleteStudentInfoByIdResponseType,
      DeleteStudentInfoByIdRequestType
    >({
      query: (payload) => ({
        url: `/students/${payload?.studentId}/remove/${payload?.referenceLabel}/${payload?.referenceLabelId}`,
        method: `DELETE`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            success,
            apiError: true,
          } as unknown as DeleteStudentInfoByIdResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as DeleteStudentInfoByIdResponseType;
        }
      },
      invalidatesTags: [`GetStudentPersonalInfo`, `GetStudentAcademicInfo`],
    }),
    deleteStudentInfoByIds: builder.mutation<
      DeleteStudentInfoByIdsResponseType,
      DeleteStudentInfoByIdsRequestType
    >({
      query: (payload) => ({
        url: `/students/${payload?.studentId}/remove/${payload?.referenceLabel}`,
        method: `POST`,
        body: { referenceLabelIds: payload.referenceLabelIds },
      }),
      invalidatesTags: (_result, _error, { referenceLabel }) => {
        if (referenceLabel === `ACADEMIC_INFO`) return [`StudentExamForm`];
        if (referenceLabel === `EXAM_INFO`) return [`StudentExamForm`];
        if (referenceLabel === `WORK_EXPERIENCE`) return [`StudentWorkForm`];
        return [`GetStudentPersonalInfo`];
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            success,
            apiError: true,
          } as unknown as DeleteStudentInfoByIdsResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as DeleteStudentInfoByIdsResponseType;
        }
      },
    }),
    getStudentExamInfo: builder.query<
      GetStudentExamDetailsResponseType,
      GetStudentProfileInfoRequestType
    >({
      query: ({ studentId }) => ({
        url: `/students/exam-info/${studentId}`,
        method: `GET`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentExamDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as GetStudentExamDetailsResponseType;
        }
      },
      providesTags: [`GetStudentExamInfo`],
    }),
    updateStudentExamInfo: builder.mutation<
      UpdateStudentExamDetailsResponseType,
      UpdateStudentExamDetailsRequestType
    >({
      query: ({
        studentId,
        englishExamType,
        examInfoDetails,
        englishExamGiven,
        gmatExamGiven,
        greExamGiven,
      }) => ({
        url: `/students/exam-info/${studentId}`,
        body: {
          englishExamGiven,
          englishExamType,
          examInfoDetails,
          gmatExamGiven,
          greExamGiven,
        },
        method: `PUT`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentExamDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as UpdateStudentExamDetailsResponseType;
        }
      },
      invalidatesTags: [
        `GetStudentExamInfo`,
        `documents`,
        `validateExamRemoval`,
        `StudentExamForm`,
      ],
    }),
    getStudentWorkInfo: builder.query<
      GetStudentWorkDetailsResponseType,
      GetStudentProfileInfoRequestType
    >({
      query: ({ studentId }) => ({
        url: `/students/work-experience/${studentId}`,
        method: `GET`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentWorkDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as GetStudentWorkDetailsResponseType;
        }
      },
      providesTags: [`GetStudentWorkInfo`],
    }),
    updateStudentWorkInfo: builder.mutation<
      UpdateStudentWorkDetailsResponseType,
      UpdateStudentWorkDetailsRequestType
    >({
      query: ({
        studentId,
        totalWorkExperienceInMonths,
        workExperiences,
        hasWorkExperience,
      }) => ({
        url: `/students/work-experience/${studentId}`,
        body: {
          totalWorkExperienceInMonths,
          workExperiences,
          hasWorkExperience,
        },
        method: `PUT`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentWorkDetailsResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as UpdateStudentWorkDetailsResponseType;
        }
      },
      invalidatesTags: [`GetStudentWorkInfo`, `documents`],
    }),
    updateStudentBackgroundInfo: builder.mutation<
      UpdateStudentBackgroundInfoResponseType,
      UpdateStudentBackgroundInfoRequestType
    >({
      query: (payload) => ({
        url: `students/${payload?.studentId}/background-information`,
        method: `POST`,
        body: payload?.body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentBackgroundInfoResponseType;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as UpdateStudentBackgroundInfoResponseType;
        }
      },
      invalidatesTags: [`GetStudentPersonalInfo`],
    }),
    fetchAllCountryData: builder.query<GetCountryCityStateResponseType, void>({
      query: () => ({
        url: `shared/countries`,
        method: `GET`,
      }),
      providesTags: [`CountryData`],
    }),
    fetchStatesByCountryData: builder.query<
      GetCountryCityStateResponseType,
      { country: string }
    >({
      query: (payload) => ({
        url: `shared/countries/${payload?.country}/state`,
        method: `GET`,
      }),
      providesTags: [`CountryAndState`],
    }),
    fetchCitiesByCountryAndStateData: builder.query<
      GetCountryCityStateResponseType,
      { country: string; state: string }
    >({
      query: (payload) => ({
        url: `shared/countries/${payload?.country}/state/${payload?.state}/city`,
        method: `GET`,
      }),
      providesTags: [`CountryAndStateAndCity`],
    }),
    fetchStudentCounsellors: builder.query<
      GetCounsellorOptions,
      GetStudentProfileInfoRequestType
    >({
      query: ({ studentId }) => `/students/${studentId}/counsellor-options`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetCounsellorOptions;
        } else {
          return {
            data,
            success,
            message,
          } as unknown as GetCounsellorOptions;
        }
      },
    }),
    validateIfExamInfoRemovable: builder.query<any, any>({
      query: ({ studentId }) => ({
        url: `v1/students/${studentId}/validate-remove/EXAM_INFO`,
        method: `GET`,
      }),
      providesTags: [`validateExamRemoval`],
    }),
    fetchCmsCountries: builder.query<GetCmsCountriesResponseType, void>({
      query: () => ({
        url: `shared/cms/countries`,
        method: `GET`,
      }),
      transformResponse: transformResponse,
      providesTags: [`CmsCountries`],
    }),
    fetchCmsStatesByCountry: builder.query<
      GetCmsStatesByCountryResponseType,
      { country: string }
    >({
      query: (payload) => ({
        url: `/shared/cms/countries/${payload.country}/state`,
        method: `GET`,
      }),
      transformResponse: transformResponse,
      providesTags: [`CmsStates`],
    }),
    fetchCmsLocations: builder.mutation<
      GetCmsLocationsResponseType,
      GetCmsLocationsRequestType
    >({
      query: (payload) => {
        return {
          method: `POST`,
          url: `/shared/cms/location`,
          body: payload,
        };
      },
      transformResponse,
    }),
  }),
});

export const {
  useGetStudentPersonalInfoQuery,
  useLazyGetStudentPersonalInfoQuery,
  useUpdateStudentPersonalInfoMutation,
  useGetStudentAcademicInfoQuery,
  useUpdateStudentAcademicInfoMutation,
  useDeleteStudentInfoByIdMutation,
  useGetStudentExamInfoQuery,
  useUpdateStudentExamInfoMutation,
  useDeleteStudentInfoByIdsMutation,
  useUpdateStudentWorkInfoMutation,
  useGetStudentWorkInfoQuery,
  useUpdateStudentBackgroundInfoMutation,
  useFetchStudentCounsellorsQuery,
  useFetchAllCountryDataQuery,
  useFetchCitiesByCountryAndStateDataQuery,
  useFetchStatesByCountryDataQuery,
  useLazyFetchCitiesByCountryAndStateDataQuery,
  useLazyFetchStatesByCountryDataQuery,
  useLazyValidateIfExamInfoRemovableQuery,
  useValidateIfExamInfoRemovableQuery,
  useFetchCmsCountriesQuery,
  useLazyFetchCmsCountriesQuery,
  useLazyFetchCmsStatesByCountryQuery,
  useFetchCmsStatesByCountryQuery,
  useFetchCmsLocationsMutation,
} = studentProfileV1Api;
