import { OPTION_TYPE } from '@leapfinance/geebee-component-library';
import debounce from 'lodash/debounce';

import { useSearchStudentMutation } from '@/services/student';

function useStudentOptions() {
  const [triggerSearchStudent] = useSearchStudentMutation();

  const fetchStudents = async (inputValue: string, callback: any) => {
    const options = [];
    const data = await triggerSearchStudent({
      searchString: inputValue,
    }).unwrap();
    if (data.success) {
      const list = data?.data?.students || [];
      for (let i = 0; i < list.length; i++) {
        options.push({
          value: list[i]?.id,
          label: list[i]?.result,
          countryCode: list[i]?.countryCode,
          contactNumber: list[i]?.contactNumber,
          email: list[i]?.email,
          cspId: list[i]?.cspId,
          cspName: list[i]?.cspName,
          country: list[i]?.issueCountryId,
        });
      }
    }
    callback(options);
  };

  const newGetStudentSuggestions = (
    query: { input: string },
    callback: (
      results?: readonly OPTION_TYPE<string, string>[] | undefined,
    ) => void,
  ) => fetchStudents(query.input, callback);

  return [debounce(newGetStudentSuggestions, 500)];
}

export default useStudentOptions;
