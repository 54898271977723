import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import {
  RecommendedCoursesForShortlistRequestType,
  RecommendedCoursesForShortlistResponseType,
} from '@/types/service';
import {
  BulkAddToShortlistRequestType,
  BulkAddToShortlistResponseType,
  CountryCurrenciesResponseType,
  GetCfCourseRequestType,
  GetCfCourseResponseType,
  GetCourseDetailsV2ResponseType,
  GetFilterValuesRequestType,
  GetFilterValuesResponseType,
  GetKeyworkSuggestionsRequestType,
  GetKeyworkSuggestionsResponseType,
  GetLinearFilterValuesRequestType,
  GetLinearFilterValuesResponseType,
  GetRandomCourseRequestType,
  GetRandomCourseResponseType,
  GetStudyDestinationSuggestionRequestType,
  GetStudyDestinationSuggestionResponseType,
  GetSuggestionsDataPathRequestType,
  GetSuggestionsDataPathResponseType,
  NoResultInfoRequestType,
  NoResultInfoResponseType,
  SourceCountriesResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

import { INRCurrencyDetails } from '../helpers';
export const geebeeCfApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestionsDataV2: builder.mutation<
      GetSuggestionsDataPathResponseType,
      GetSuggestionsDataPathRequestType
    >({
      query: (payload) => {
        const request: FetchArgs = {
          url: `/cf`,
          body: payload[`courseFinderRequestDto`],
          method: `POST`,
          params: {
            sameSpecialisation: payload.sameSpecialisation,
            sameSubStream: payload.sameSubStream,
            aggregation: payload.aggregation,
          },
        };
        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetSuggestionsDataPathResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetSuggestionsDataPathResponseType;
        }
      },
    }),
    getCFKeywordSuggestions: builder.mutation<
      GetKeyworkSuggestionsResponseType,
      GetKeyworkSuggestionsRequestType
    >({
      query: (payload) => {
        const request: FetchArgs = {
          url: `/cf/keywords/suggestion`,
          body: payload,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetKeyworkSuggestionsResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetKeyworkSuggestionsResponseType;
        }
      },
    }),
    getStudyDestinationSuggestions: builder.mutation<
      GetStudyDestinationSuggestionResponseType,
      GetStudyDestinationSuggestionRequestType
    >({
      query: (payload) => {
        const request: FetchArgs = {
          url: `/cf/study-destination/suggestion`,
          body: payload,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudyDestinationSuggestionResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetStudyDestinationSuggestionResponseType;
        }
      },
    }),
    bulkAddToShortlist: builder.mutation<
      BulkAddToShortlistResponseType,
      BulkAddToShortlistRequestType
    >({
      query: ({ studentId, courses }) => {
        const request: FetchArgs = {
          url: `/v1/shortlist/student/${studentId}/bulk`,
          body: courses,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BulkAddToShortlistResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as BulkAddToShortlistResponseType;
        }
      },
    }),
    getSourceCountries: builder.query<SourceCountriesResponseType, void>({
      query: () => `/cf/source-countries`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as SourceCountriesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as SourceCountriesResponseType;
        }
      },
    }),
    getFilterValues: builder.mutation<
      GetFilterValuesResponseType,
      GetFilterValuesRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/cf/filter-values`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetFilterValuesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetFilterValuesResponseType;
        }
      },
    }),
    getCountryCurrencies: builder.mutation<
      CountryCurrenciesResponseType,
      { sourceCountryId: number }
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/cf/currencies/view-all`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: {
              currencies: INRCurrencyDetails,
              minValueInr: 0,
              maxValueInr: 20000000,
            },
            message,
            apiError: true,
          } as unknown as CountryCurrenciesResponseType;
        } else {
          return {
            success,
            data: {
              ...data,
              currencies: data?.currencies ?? INRCurrencyDetails,
              minValueInr: data?.minValueInr ?? 0,
              maxValueInr: data?.maxValueInr ?? 20000000,
            },
            message,
          } as unknown as CountryCurrenciesResponseType;
        }
      },
    }),
    getRandomCourse: builder.query<
      GetRandomCourseResponseType,
      GetRandomCourseRequestType
    >({
      query: () => `/cf/random/course`,
      transformResponse: transformResponse,
    }),
    getRecommendedCourses: builder.query<
      RecommendedCoursesForShortlistResponseType,
      RecommendedCoursesForShortlistRequestType
    >({
      query: (payload) => {
        return {
          url: `/cf/recommended/shortlist-courses`,
          method: `POST`,
          body: payload,
        };
      },
    }),
    getLinearFilterValues: builder.mutation<
      GetLinearFilterValuesResponseType,
      GetLinearFilterValuesRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/cf/linear-filter-values`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getNoResultInfo: builder.mutation<
      NoResultInfoResponseType,
      NoResultInfoRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/cf/no-result-info`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getCourseDetails: builder.query<
      GetCfCourseResponseType,
      GetCfCourseRequestType
    >({
      query: (payload) => {
        const request: FetchArgs = {
          url: `/cf/${payload.courseId}${
            payload.studentId ? `?studentId=${payload.studentId}` : ``
          }`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetCfCourseResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetCfCourseResponseType;
        }
      },
    }),
    getCourseDetailsV2: builder.query<
      GetCourseDetailsV2ResponseType,
      { courseId: number }
    >({
      query: ({ courseId }) => `/cf/v2/course/${courseId}`,
      transformResponse,
    }),
    getCFCourseDetailsById: builder.query<any, { id: number }>({
      query: ({ id }) => `cf/course/${id}`,
      transformResponse,
    }),
  }),
});

export const {
  useGetSuggestionsDataV2Mutation,
  useGetCFKeywordSuggestionsMutation,
  useGetStudyDestinationSuggestionsMutation,
  useBulkAddToShortlistMutation,
  useGetSourceCountriesQuery,
  useGetFilterValuesMutation,
  useGetCountryCurrenciesMutation,
  useLazyGetRandomCourseQuery,
  useLazyGetRecommendedCoursesQuery,
  useGetLinearFilterValuesMutation,
  useGetNoResultInfoMutation,
  useGetCourseDetailsQuery,
  useGetCourseDetailsV2Query,
  useGetCFCourseDetailsByIdQuery,
  useLazyGetSourceCountriesQuery,
} = geebeeCfApi;
