import { isFalsy } from '@leapfinance/frontend-commons';
import { BaseOptionType } from '@leapfinance/frontend-commons/schema/common';
import { OptionType } from '@leapfinance/geebee-component-library';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import { definitions } from '@/types/schema';
import { BaseResponse } from '@/types/Services';

export const breakCamelCase = (value: string) => {
  return value.replace(/([A-Z])/g, ` $1`).replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const breakSnakeCase = (value: string) => {
  return value.split(`_`).join(` `);
};

export interface IntakeStatusInterface {
  id: number | null;
  year: number | null;
  month: string | null;
  status: string | null;
  open_date: string | null;
  deadline: string | null;
}

const currentDate = new Date();
export const CURRENT_MONTH = currentDate.getMonth();
export const CURRENT_YEAR = currentDate.getFullYear();

export const months = [
  `jan`,
  `feb`,
  `mar`,
  `apr`,
  `may`,
  `jun`,
  `jul`,
  `aug`,
  `sep`,
  `oct`,
  `nov`,
  `dec`,
];
export const getMonthNumber = (month: string) => {
  return months?.findIndex((ele: string) => ele === month?.toLowerCase());
};

export const getFilteredUpdatedIntake = (intakes: IntakeStatusInterface[]) =>
  intakes?.filter(({ year, month }: IntakeStatusInterface) => {
    if (year && month) {
      const intakeMonth = getMonthNumber(month);
      return (
        year > CURRENT_YEAR ||
        (year === CURRENT_YEAR && intakeMonth >= CURRENT_MONTH)
      );
    }
    return false;
  });

export const getFinalFormFieldNames = <T extends Record<any, any>>(
  obj: T,
  prefix: string,
  seperator: string,
): Record<keyof T, string> => {
  const formNamesWithPrefix: Record<keyof T, string> = {} as Record<
    keyof T,
    string
  >;

  Object.entries(obj).forEach(([key, value]) => {
    formNamesWithPrefix[key as keyof T] = prefix + seperator + value;
  });

  return formNamesWithPrefix;
};

export const startCaseToLower = (text: string) => {
  return startCase(toLower(text));
};

export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
      success: false,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};

/**
Transforms an array of objects to an array of options that are compatible with react-select/mui-select.
@param {Object[]} options - An array of objects containing labelKey and labelValue properties.
@returns {BaseOptionType[]} An array of options that can be passed to a react-select component.
*/
export const getLabelValueTranformOptions = (
  options: definitions[`ListDto`][],
): BaseOptionType[] => {
  return (
    options?.map((option) => ({
      label: option.labelValue ?? ``,
      value: option.labelKey,
    })) ?? []
  );
};

export const getGraduationYear = ({
  startYear,
  count,
}: {
  startYear: number;
  count: number;
}): BaseOptionType[] => {
  return new Array(count)
    .fill(0)
    .map((_, index) => ({
      label: String(startYear + index + 1),
      value: startYear + index + 1,
    }))
    .reverse();
};

export const scrollToElementWithOffset = (id: string, headerOffset = 64) => {
  const element = document?.querySelector(id);
  const elementPosition = element?.getBoundingClientRect().top;
  const offsetPosition =
    (elementPosition || 0) + window.pageYOffset - headerOffset;

  scrollTo({
    top: offsetPosition,
    behavior: `smooth`,
  });
};

export const elementIsVisibleInViewport = (
  el: any,
  partiallyVisible = false,
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

function makeImagesResponsive(document: Document) {
  // Select all img elements in the document
  const images = document.querySelectorAll(`img`);

  // Loop through all img elements and update their styles
  images.forEach((img) => {
    // Remove width and height attributes
    // img.removeAttribute(`width`);
    // img.removeAttribute(`height`);

    // Apply responsive CSS styles
    img.style.maxWidth = `100%`;
    img.style.height = `auto`;

    const wrapperDiv = document.createElement(`div`);
    wrapperDiv.classList.add(`overflow-auto`);

    const imgParent = img.parentElement;

    imgParent?.insertBefore(wrapperDiv, img);
    wrapperDiv.appendChild(img);
  });
}

function makeTablesResponsive(document: Document) {
  // Select all table elements in the document
  const tables = document.querySelectorAll(`table`);

  // Loop through all table elements and update their styles
  tables.forEach((table) => {
    // Remove width and height attributes
    table.removeAttribute(`width`);
    table.removeAttribute(`height`);

    // Apply responsive CSS styles
    table.style.width = `100%`;
    table.style.height = `auto`;

    // Check if the table has the border="1" attribute
    if (table.getAttribute(`border`) === `1`) {
      // Select all td elements in the table and apply border styles
      const cells = table.querySelectorAll(`td`);
      cells.forEach((cell) => {
        cell.style.border = `1px solid #000`; // Add a border to each cell
      });
    }

    const wrapperDiv = document.createElement(`div`);
    wrapperDiv.classList.add(`overflow-auto`);

    const tableParent = table.parentElement;

    tableParent?.insertBefore(wrapperDiv, table);

    wrapperDiv.appendChild(table);
  });
}

function updateAnchorTags(document: Document) {
  // Select all a elements in the document
  const anchors = document.querySelectorAll(`a`);

  // Loop through all a elements and set target attribute
  anchors.forEach((anchor) => {
    // Set target attribute to _blank
    anchor.setAttribute(`target`, `_blank`);
    anchor.classList.add(`text-blue-500`, `hover:underline`);
  });
}

interface AttachmentMap {
  [contentId: string]: string; // Key is Content-ID, value is S3 URL
}

export function replaceCidUrls(
  document: Document,
  attachments: AttachmentMap,
): void {
  // Select all img elements
  const imgElements = document.querySelectorAll(`img`);

  // Loop through all img elements and replace CID URLs with S3 URLs
  imgElements.forEach((img) => {
    const src = img.getAttribute(`src`);
    if (src && src.startsWith(`cid:`)) {
      const cid = src.substring(4); // Remove 'cid:' prefix
      const s3Url = attachments[cid];
      if (s3Url) {
        img.setAttribute(`src`, s3Url);
      }
    }
  });
}

const removeHardCodedWidthsFromExternalStyleSheet = (document: Document) => {
  // Step 1: Get the style node from the DOM
  const styleTags = document.querySelectorAll(`style`);

  styleTags.forEach((styleNode) => {
    if (styleNode) {
      // Step 2: Parse the content of the style node
      const cssText = styleNode.textContent;

      // Step 3-4: Replace hardcoded width values with 100%
      if (cssText) {
        const modifiedCssText = cssText.replace(
          /width:\s*\d+(px|rem|em|%);?/g,
          `width: 100%;`,
        );

        // Step 5: Update the content of the style node with modified CSS rules
        styleNode.textContent = modifiedCssText;
      }
    }
  });
};

/**
 * This converts string content to compatible html string to be added in DOM
 * @param content
 * @returns emailString
 */
export const convertToHtmlString = (
  content: string,
  attachments?: AttachmentMap,
): string => {
  try {
    if (isFalsy(content)) {
      return ``;
    }

    // created with scripting disabled refer: https://stackoverflow.com/questions/64772302/is-parsing-html-with-domparser-safe-from-xss#:~:text=DOMParser%20created%20documents%20are%20created,should%20be%20safe%20against%20XSS.
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(content, `text/html`);
    // change img width to dynamic
    makeImagesResponsive(parsedHtml);
    makeTablesResponsive(parsedHtml);
    updateAnchorTags(parsedHtml);
    replaceCidUrls(parsedHtml, attachments ?? {});
    removeHardCodedWidthsFromExternalStyleSheet(parsedHtml);

    // Extract the content you need from the parsed document
    return parsedHtml.body.innerHTML;
  } catch (err) {
    return ``;
  }
};

export const renderLabelForGBDropdowns = (
  option: string | OptionType,
  value: string | OptionType,
  groupBy: 'label' | 'value',
) => {
  if (typeof option === `string`) return false;
  if (typeof value === `string`) return option?.label === value;
  return groupBy === `value`
    ? option?.value === value?.value
    : option?.label === value?.label;
};

/**
   * Validates if the value passed is a valid number or not. Do not do any transformations before
   * passing the value to this function.
   *
   * @param {any} value - The value to be validated, to send a raw, untransformed value
   * @returns {true | false} Returns if the value passed is a valid number or not
   
   *
   * @example
   * // Validate if router?.query?.studentId is a valid number
   * const result = isValidNumber(router?.query?.studentId); // This will return if the sent parameter is a valid number.
   */
export const isValidNumber = (value: any) => {
  if (isFalsy(value) || typeof value === `boolean`) return false;
  return !Number.isNaN(Number(value));
};
