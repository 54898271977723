import axios from 'axios';

import { isValidNumber } from '@/utils/common';

export const fetchGeebeeVisaCounsellors = async (appId: number) => {
  try {
    if (!isValidNumber(appId)) return;
    const res = await axios.get(
      `/api/geebeeRoute?path=visa/counsellor/${appId}`,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err: any) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const fetchVisaRemarks = async (visaId: number | null) => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=visa/${visaId}/remark`);
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err: any) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const updateVisaRemarks = async (visaPayload: object) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=visa/remark/gb`,
      visaPayload,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err: any) {
    return `Something went wrong. Please contact the tech team`;
  }
};
